<script>
import api from '@/services/api';
import { BCol, BFormGroup, BFormInput, BRow } from 'bootstrap-vue';
import LogsTimeline from '@/components/LogsTimeline';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { MERCHANT_ID } from '@/constants/names';
import { formatToPrice } from '@/util/number';
import FlatPickr from 'vue-flatpickr-component';

export default {
    name: 'PaynetStatistics',
    components: {
        BFormInput,
        LogsTimeline,
        FlatPickr,
        BRow,
        BCol,
        BFormGroup,
        vSelect,
        // eslint-disable-next-line vue/no-unused-components
        ToastificationContent
    },
    directives: {
        Ripple
    },
    data: () => ({
        transactions: {
            items: [],
            pagination: {}
        },
        apps: [
            {
                id: '',
                name: ''
            }
        ],
        filter: {
            merchant_id: parseInt(MERCHANT_ID),
            page: 1,
            limit: 20,
            search: '',
            confirmed: null,
            service: [],
            created_at: []
        },
        paynet: {
            phone: '',
            amount: null,
            comment: ''
        },
        servicesList: [],
        setPaynetPermission: null,
        balance: null,
        requestPending: false
    }),
    computed: {
        confirmingList() {
            return [
                {
                    label: this.$t('titles.confirmed'),
                    key: true
                },
                {
                    label: this.$t('titles.not_confirmed'),
                    key: false
                }
            ];
        }
    },
    methods: {
        formatToPrice,

        async getTransactions() {
            const body = {
                page: this.filter.page,
                limit: this.filter.limit,
                merchant_id: this.filter.merchant_id,
                filters: {
                    confirmed: this.filter.confirmed,
                    service: this.filter.service,
                    created_at: this.filter.created_at
                },
                search: this.filter.search
            };
            // deletePropertiesWhichNoValue(this.filter);
            this.requestPending = true;
            const { data } = await api.paynetApiV2.fetchAllTransaction(body);
            if (data.data.pagination.current === 1) {
                this.transactions.items = data.data.list;
            } else {
                this.transactions.items = [...this.transactions.items, ...data.data.list];
            }
            this.transactions.pagination = data.data.pagination;
            this.requestPending = false;
        },

        async getServicesList() {
            try {
                const { data } = await api.paynetApiV2.fetchServicesList({
                    merchant_id: parseInt(MERCHANT_ID)
                });
                this.servicesList = data.data;
            } catch (e) {
                console.error(e);
            }
        },

        async getBalance() {
            try {
                const { data } = await api.paynetApiV2.fetchBalance({
                    merchant_id: parseInt(MERCHANT_ID)
                });
                this.balance = data.data.balance;
            } catch (e) {
                console.error(e);
            }
        },

        async loadNextPage(page) {
            if (this.requestPending || !page) return;
            this.filter.page = page;
            await this.getTransactions();
        },

        async getApps() {
            const { data } = await api.paynetStatistics.getAppsApi();
            this.apps = data;
        },

        deSelect(val) {
            if (!val) {
                window.scrollTo(0, 0);
                this.filter.page = 1;
                this.getTransactions();
            }
        },

        getAppName(appID) {
            const cApp = this.apps.find((app) => app.id === appID);
            if (cApp) {
                return cApp.name;
            }

            return '';
        },

        async setPaynet() {
            const body = {
                merchant_id: parseInt(MERCHANT_ID),
                phone: parseInt(this.paynet.phone),
                amount: parseInt(this.paynet.amount),
                comment: this.paynet.comment
            };

            const toast = {
                title: this.$t('paynet_texts.success'),
                icon: 'CheckIcon',
                variant: 'success'
            };

            const { data } = await api.paynetApiV2.setPaynet(body);
            if (data.data.confirmed) {
                await this.showToast(toast);
                await this.clearPaynetFields();
            } else {
                await this.showToast({
                    title: this.$t('error.smth'),
                    icon: 'XIcon',
                    variant: 'danger'
                });
            }
        },

        clearPaynetFields() {
            this.paynet.phone = null;
            this.paynet.amount = null;
            this.paynet.comment = null;
        },

        clearFilterFields() {
            this.filter.merchant_id = parseInt(MERCHANT_ID);
            this.filter.page = 1;
            this.filter.limit = 20;
            this.filter.confirmed = null;
            this.filter.service = null;
            this.filter.created_at = [];
            this.filter.search = '';
            this.getTransactions();
        },

        showToast({ title, icon, variant }) {
            this.$toast(
                {
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: icon,
                        variant
                    }
                },
                {
                    position: 'top-right'
                }
            );
        },
        getPaynetPermission() {
            const userData = JSON.parse(localStorage.getItem('userData'));
            this.setPaynetPermission = userData.role.permission.paynet.create;
        }
    },
    mounted() {
        this.getPaynetPermission();
        Promise.any([
            this.getTransactions(),
            this.getBalance(),
            this.getServicesList()
            // this.getApps()
        ]);
    }
};
</script>

<template>
    <div>
        <b-row>
            <b-col class="col-lg-6 col-sm-7 col-12 order-2 order-sm-1">
                <logs-timeline
                    v-model:loading="requestPending"
                    :items="transactions.items"
                    :pagination="transactions.pagination"
                    @loadNextPage="loadNextPage"
                >
                    <template #default="{ item }">
                        <div class="d-flex">
                            <h5>{{ item.phone }}</h5>
                            <small class="ml-auto text-right">
                                {{ item.created_at }}
                            </small>
                        </div>
                        <div>{{ $t('titles.sum') }}: {{ formatToPrice(item.amount) }}</div>
                        <div>
                            {{ $t('titles.service') }}:
                            <b-badge variant="primary">{{ item.service.toUpperCase() }}</b-badge>
                        </div>
                        <div>
                            {{ $t('titles.confirmed') }}:
                            <feather-icon
                                :class="[item.confirmed ? 'text-success' : 'text-danger']"
                                :icon="item.confirmed ? 'CheckIcon' : 'XIcon'"
                                size="20"
                            />
                        </div>
                        <div>{{ $t('titles.comments') }}: {{ item.comment }}</div>
                    </template>
                </logs-timeline>
            </b-col>
            <b-col class="col-lg-6 col-sm-5 col-12 order-1 order-sm-2">
                <b-card class="filter-sticky">
                    <!--                <b-card class="mt-2 paynet-sticky">-->
                    <div class="d-flex align-items-center justify-content-lg-between">
                        <h2 class="mb-2">{{ $t('filter_title') }}</h2>
                    </div>
                    <b-col class="mb-2" cols="12">
                        <h5>{{ $t('titles.service') }}</h5>
                        <b-form-group>
                            <v-select
                                v-model="filter.service"
                                :options="servicesList"
                                :reduce="(type) => type"
                                :searchable="false"
                                :multiple="true"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col class="mb-2" cols="12">
                        <h5>{{ $t('titles.status') }}</h5>
                        <b-form-group>
                            <v-select
                                v-model="filter.confirmed"
                                :options="confirmingList"
                                :reduce="(type) => type.key"
                                :searchable="false"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <h5>{{ $t('titles.phone_number') }} | {{ $t('titles.user_id') }}</h5>
                        <b-form-group>
                            <b-form-input
                                v-model="filter.search"
                                :placeholder="`${$t('titles.phone_number')} | ${$t(
                                    'titles.user_id'
                                )}`"
                                size="lg"
                            />
                        </b-form-group>
                    </b-col>
                    <div class="d-flex align-items-center">
                        <b-col cols="6" class="mt-1">
                            <h5>{{ $t('titles.from') }}</h5>
                            <flat-pickr
                                id="start_date"
                                v-model="filter.created_at[0]"
                                :config="{
                                    allowInput: true,
                                    time_24hr: true
                                }"
                                :placeholder="$t('choose.date')"
                                autocomplete="off"
                                class="form-control"
                                name="date"
                            />
                        </b-col>
                        <b-col cols="6" class="mt-1">
                            <h5>{{ $t('titles.to') }}</h5>
                            <flat-pickr
                                id="end_date"
                                v-model="filter.created_at[1]"
                                :config="{
                                    allowInput: true,
                                    time_24hr: true
                                }"
                                :placeholder="$t('choose.date')"
                                autocomplete="off"
                                class="form-control"
                                name="date"
                            />
                        </b-col>
                    </div>

                    <div class="d-flex align-items-center justify-content-end mt-2">
                        <b-button
                            @click="clearFilterFields"
                            variant="outline-secondary"
                            class="mr-1"
                        >
                            {{ $t('paynet_texts.reset') }}
                        </b-button>
                        <b-button @click="getTransactions" variant="outline-primary">{{
                            $t('button.filter')
                        }}</b-button>
                    </div>
                </b-card>

                <b-card v-if="setPaynetPermission" class="mt-2 paynet-sticky">
                    <!--                <b-card class="mt-2 paynet-sticky">-->
                    <div class="d-flex align-items-center justify-content-lg-between">
                        <h2 class="mb-2">Paynet</h2>
                        <h2 class="mb-2" v-if="balance">
                            {{ $t('titles.balance') }}: {{ formatToPrice(balance) }}
                        </h2>
                    </div>
                    <b-form-group :label="$t('titles.phone_number')" label-for="phone">
                        <b-form-input
                            v-model="paynet.phone"
                            id="phone"
                            :placeholder="$t('enter.phone_number')"
                        />
                    </b-form-group>
                    <b-form-group :label="$t('titles.sum')" label-for="sum" class="mt-1">
                        <b-form-input
                            v-model="paynet.amount"
                            id="sum"
                            :placeholder="$t('enter.sum')"
                        />
                    </b-form-group>
                    <b-form-group :label="$t('titles.comments')" label-for="sum" class="mt-1">
                        <b-form-textarea
                            v-model="paynet.comment"
                            id="sum"
                            :placeholder="$t('enter.comment')"
                        />
                    </b-form-group>

                    <div class="d-flex align-items-center justify-content-end mt-2">
                        <b-button
                            @click="clearPaynetFields"
                            variant="outline-secondary"
                            class="mr-1"
                        >
                            {{ $t('paynet_texts.reset') }}
                        </b-button>
                        <b-button @click="setPaynet" variant="outline-primary">{{
                            $t('button.send')
                        }}</b-button>
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped>
.paynet-sticky {
    width: 100%;
    position: sticky;
    top: 370px;
    z-index: 9;
}
</style>
